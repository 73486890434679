import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de 3-4 cm de diàmetre, convex o un poc aplanat, de color blanc amb alguna zona de color crema ocraci o grisenc. El marge és ondulat d’adult. Té nombroses làmines, adherents, desiguals de color blanc o crema i el peu és cilíndric, corbat, més estret cap a la base, de color blanc. Les espores són blanques-cremoses en massa, el·lipsoidals, quasi rodones, de 4 x 3 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      